import React from "react";

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

function LandingPageHeader() {
  let pageHeader = React.createRef();
  const contentres = window.innerWidth < 1498 ? "content-center" : "content-left"

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  React.useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      if (window.innerWidth < 1498) {
        document.getElementById('content').classList.add("content-center")
        document.getElementById('content').classList.remove("content-left")
      }
      else {
        document.getElementById('content').classList.add("content-left")
        document.getElementById('content').classList.remove("content-center")
      }
    }, 500)

    window.addEventListener('resize', debounceHandleResize)

    return _ => {
      window.removeEventListener('resize', debounceHandleResize)
    }

  });
  return (
    <>
      <div className="page-header">
          <picture>
            <source srcSet={require("assets/img/landinghead.webp")} type="image/webp"></source>
            <img src={require("assets/img/landinghead.jpg")} className="page-header-image" ref={pageHeader} alt="header-image"></img>
          </picture>
        <div id="content" className={contentres}>
          <div className="text-left">
            <h1 className="title">Gemeinsam führen wir Sie an Ihr Ziel</h1>
            <h3>Swiss Rail Services GmbH steht Ihnen als bewährter Partner zur Verfügung, wenn es um die Vermittlung und den Verleih von kompetentem Bahnpersonal geht.</h3>
            <div
              style={{
                backgroundImage: "url(" + require("assets/img/swissstaffing-logo.png") + ")",
                backgroundColor: "white",
                width: 180,
                height: 40,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
